import React from 'react'
import { graphql } from 'gatsby'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import BlogArticleIndex from '../components/views/BlogArticleIndex/BlogArticleIndex'

const BlogArticleIndexTemplate = ({ data, pageContext, location }) => {
  return (
    <>
      <Seo
        title={data.page.data.title.text}
        description='The latest news and insights on coding and the tech industry'
        slug={location.pathname}
      />
      <BlogArticleIndex
        data={data}
        pageContext={pageContext}
      />
    </>
  )
}

export default BlogArticleIndexTemplate

export const PageQuery = graphql`
  query BlogArticleIndexTemplateQuery ($skip: Int!, $limit: Int!, $categoryUid: String) {
    page: prismicPage(
      uid: {
        eq: "blog"
      }
    ) {
      uid
      data {
        title {
          text
        }
      }
      ...PreFooter
      ...Twitter
    }
    blogArticles: allPrismicBlogArticle (
      filter: {
        data: {
          is_featured: {
            ne: true
          },
          categories: {
            elemMatch: {
              category: {
                uid: {
                  glob: $categoryUid
                }
              }
            }
          }
        }
      },
      sort: {
        order: DESC,
        fields: data___date
      },
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        ...BlogArticle
      }
    }
    featuredBlogArticles: allPrismicBlogArticle (
      filter: {
        data: {
          is_featured: {
            eq: true
          },
          categories: {
            elemMatch: {
              category: {
                uid: {
                  glob: $categoryUid
                }
              }
            }
          }
        }
      },
      sort: {
        order: DESC,
        fields: data___date
      },
      limit: 1
      skip: $skip
    ) {
      nodes {
        ...BlogArticle
      }
    }
    blogCategories: allPrismicBlogCategory (
      sort: {
        fields: data___title___text,
        order: ASC
      }
    ) {
      nodes {
        id
        uid
        url
        data {
          title {
            text
          }
        }
      }
    }
  }
`
